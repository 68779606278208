<template>
  <bubble-menu
      :editor="editor"
      :tippy-options="{ duration: 100 }"
      v-if="editor"
    >
      <div class="bubble-menu">
        <button @click="editor.chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }">
          Bold
        </button>
        <button @click="editor.chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }">
          Italic
        </button>
        <button @click="editor.chain().focus().toggleStrike().run()" :class="{ 'is-active': editor.isActive('strike') }">
          Strike
        </button>
      </div>
    </bubble-menu>
  <editor-content :editor="editor" class="border-solid border-2 border-gray-300 rounded min-h-28 tiptap-window"/>
</template>

<script>
import StarterKit from '@tiptap/starter-kit'
import { BubbleMenu, Editor, EditorContent } from '@tiptap/vue-3'
import FileHandler from '@tiptap-pro/extension-file-handler'
import Image from '@tiptap/extension-image'
import {Link} from '@tiptap/extension-link'
import Text from '@tiptap/extension-text'
import { defineComponent } from 'vue'
import apiCalls from '../services/apiCalls.js'
import Swal from 'sweetalert2'

export const CustomLink = Link.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      class: {
        default: null,
        parseHTML: element => element.getAttribute('class'),
        renderHTML: attributes => {
          if (!attributes.class) {
            return {};
          }

          return {
            class: attributes.class,
          };
        },
      },
    };
  },
});



export default defineComponent({
  components: {
    EditorContent,
    BubbleMenu
  },

  props: {
    modelValue: {
      type: String,
      default: '',
    }
  },

  emits: ['update:modelValue'],

  data() {
    return {
      editor: null,
      filename: "",
      isEditable: true,
    }
  },

  watch: {
    modelValue(value) {
      // HTML
      const isSame = this.editor.getHTML() === value

      // JSON
      // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

      if (isSame) {
        return
      }

      this.editor.commands.setContent(value, false)
    },
    isEditable(value) {
      this.editor.setEditable(value)
    },
  },
  computed: {
    isEditorActive() {
      return this.editor ? this.editor.isActive('textStyle') : false;
    },
  },
  methods: {
    fireModal(currentEditor, inFilename, file) {
      console.log(file)
      
      Swal.fire({
        title: "Välj ett filnamn",
        input: "text",
        inputValue: inFilename,
        inputAttributes: {
            autocapitalize: "off"
        },
        showCancelButton: true,
        confirmButtonText: "Ok",
        showLoaderOnConfirm: true,
        preConfirm: async (filename) => {
          let modFilename = filename.replace(/\s/g, '');
          apiCalls.uploadImage(Swal, file, modFilename).then(resp => {console.log(resp)
          let apiurl = process.env.VUE_APP_APIURL_WEB
          if (process.env.NODE_ENV=="development") {
            apiurl = process.env.VUE_APP_APIURL_LOCAL
          }
          let imageUrl = apiurl + "/images/medium/" + "mid_" + resp.data.file
          let fullSizeLink = apiurl + "/images/fullsize/" + resp.data.file


            this.insertImageAndLink(imageUrl, 
            "Fullstorlek", fullSizeLink)
          })
        },
        allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
        console.log("RESULT", result)
      });

    },
    insertImageAndLink(imageUrl, linkText,linkUrl) {
      this.editor.chain()
        .focus()
        .insertContent('<br/>')
        .run();

      this.editor
          .chain()
          .focus()
          .insertContent('<p></p>')
          .run();

      this.editor.chain().focus().setImage({ src: imageUrl }).run();

      this.editor.chain()
        .focus() // Focus the editor
        .setParagraph() // Set the current block to a paragraph
        .insertContent(`<p><a href="${linkUrl}" class="image-link">${linkText}</a><br /></p>`) // Insert the link with a class inside the paragraph
        .run(); // Execute the command

    },
  },

  mounted() {
    this.editor = new Editor({
      extensions: [
        StarterKit,
        Text,
        Image.configure({
          inline: true,
          HTMLAttributes: {
            class: 'image-class',
          },
        }),
        Link.extend({
            exitable: true,
            inclusive: false
        }),
        FileHandler.configure({
          allowedMimeTypes: ['image/png', 'image/jpeg', 'image/gif', 'image/webp'],
          onDrop: (currentEditor, files) => {
            files.forEach(file => {
              console.log("filename", file.name)
              this.fireModal(file.name)
            })
          },
          onPaste: (currentEditor, files) => {
            files.forEach(file => {
              this.fireModal(currentEditor, file.name, file)
              console.log("Running onpaste")
              
              console.log("file name",file.name)
            })
          }
        })
      ],
      content: this.modelValue,
      onUpdate: () => {
        // HTML
        this.$emit('update:modelValue', this.editor.getHTML())

        // JSON
        // this.$emit('update:modelValue', this.editor.getJSON())
      },
    })
  },

  

  beforeUnmount() {
    this.editor.destroy()
  },
})
</script>

<style lang="scss">
/* Basic editor styles */
.tiptap {
  :first-child {
    margin-top: 0;
  }

  .ProseMirror {
    min-height: 7rem;
  }

  /* List styles */
  ul,
  ol {
    padding: 0 1rem;
    margin: 1.25rem 1rem 1.25rem 0.4rem;

    li p {
      margin-top: 0.25em;
      margin-bottom: 0.25em;
    }
  }

  /* Heading styles */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
    margin-top: 2.5rem;
    text-wrap: pretty;
  }

  h1,
  h2 {
    margin-top: 3.5rem;
    margin-bottom: 1.5rem;
  }

  h1 {
    font-size: 1.4rem;
  }

  h2 {
    font-size: 1.2rem;
  }

  h3 {
    font-size: 1.1rem;
  }

  h4,
  h5,
  h6 {
    font-size: 1rem;
  }

  /* Code and preformatted text styles */
  code {
    background-color: theme("colors.purple.200");
    border-radius: 0.4rem;
    color: black;
    font-size: 0.85rem;
    padding: 0.25em 0.3em;
  }

  pre {
    background: black;
    border-radius: 0.5rem;
    color: theme("colors.gray.300");
    font-family: 'JetBrainsMono', monospace;
    margin: 1.5rem 0;
    padding: 0.75rem 1rem;

    code {
      background: none;
      color: inherit;
      font-size: 0.8rem;
      padding: 0;
    }
  }

  blockquote {
    border-left: 3px solid theme("colors.gray.300");
    margin: 1.5rem 0;
    padding-left: 1rem;
  }

  hr {
    border: none;
    border-top: 1px solid theme("colors.gray.200");
    margin: 2rem 0;
  }
}

.tiptap.ProseMirror {
    min-height: 7rem;
}

.tiptap-window p {
  text-align: left;
  padding: 0 1em;
  overflow: auto;
}

/* Bubble menu */
.bubble-menu {
  background-color: white;
  border: 1px solid theme("colors.gray.100");
  border-radius: 0.7rem;
  box-shadow: theme("boxShadow.md");
  display: flex;
  padding: 0.2rem;

  button {
    background-color: unset;

    &:hover {
      background-color: theme("colors.gray.300");
    }

    &.is-active {
      background-color: theme("colors.purple.300");

      &:hover {
        background-color: theme("colors.purple.400");
      }
    }
  }
}


</style>